/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.2.1/dist/css/bootstrap-reboot.min.css
 * - /npm/bootstrap@4.2.1/dist/css/bootstrap-grid.min.css
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/native-toast@2.0.0/dist/native-toast.min.css
 * - /npm/css-percentage-circle@0.2.0/css/circle.min.css
 * - /npm/animate.css@3.7.2/animate.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
