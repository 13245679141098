.native-toast {
  position: fixed;
  background-color: rgba(50,50,50,0.8);
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 10px 12px;
  opacity: 0;
  z-index: 99999;
  transition: opacity 0.25s, top 0.25s, bottom 0.25s, -webkit-transform 0.25s;
  transition: transform 0.25s, opacity 0.25s, top 0.25s, bottom 0.25s;
  transition: transform 0.25s, opacity 0.25s, top 0.25s, bottom 0.25s, -webkit-transform 0.25s;
  box-sizing: border-box;
  max-width: 18rem;
}
.native-toast-north {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: -50px;
}
.native-toast-north.native-toast-shown {
  opacity: 1;
  top: 50px;
}
.native-toast-north.native-toast-shown.native-toast-edge {
  top: 0;
}
.native-toast-north-west {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: -50px;
  left: 50px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.native-toast-north-west.native-toast-shown {
  opacity: 1;
  top: 50px;
}
.native-toast-north-west.native-toast-shown.native-toast-edge {
  top: 0;
}
.native-toast-north-east {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: -50px;
  left: auto;
  right: 50px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.native-toast-north-east.native-toast-shown {
  opacity: 1;
  top: 50px;
}
.native-toast-north-east.native-toast-shown.native-toast-edge {
  top: 0;
}
.native-toast-south {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -50px;
}
.native-toast-south.native-toast-shown {
  opacity: 1;
  bottom: 50px;
}
.native-toast-south.native-toast-shown.native-toast-edge {
  bottom: 0;
}
.native-toast-south-west {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -50px;
  left: 50px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.native-toast-south-west.native-toast-shown {
  opacity: 1;
  bottom: 50px;
}
.native-toast-south-west.native-toast-shown.native-toast-edge {
  bottom: 0;
}
.native-toast-south-east {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -50px;
  left: auto;
  right: 50px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.native-toast-south-east.native-toast-shown {
  opacity: 1;
  bottom: 50px;
}
.native-toast-south-east.native-toast-shown.native-toast-edge {
  bottom: 0;
}
.native-toast-center {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  bottom: -50px;
}
.native-toast-center.native-toast-shown {
  opacity: 1;
  bottom: 50%;
}
.native-toast-west {
  left: 50px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  bottom: -50px;
}
.native-toast-west.native-toast-shown {
  opacity: 1;
  bottom: 50%;
}
.native-toast-east {
  left: auto;
  right: 50px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  bottom: -50px;
}
.native-toast-east.native-toast-shown {
  opacity: 1;
  bottom: 50%;
}
.native-toast-edge {
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  text-align: left;
  left: 0;
  -webkit-transform: none;
          transform: none;
}
.native-toast-error {
  background-color: #ff3d3d;
  color: #fff;
}
.native-toast-success {
  background-color: #62a465;
  color: #fff;
}
.native-toast-warning {
  background-color: #ffb618;
  color: #fff;
}
.native-toast-info {
  background-color: #67daff;
  color: #fff;
}
[class^='native-toast-icon-'] {
  vertical-align: middle;
  margin-right: 5px;
}
[class^='native-toast-icon-'] svg {
  width: 16px;
  height: 16px;
}
